import React from "react"
import Section from "elements/Section"

import UploadDocumentDropzone from "./EnrollmentUpload/UploadDocumentDropzone"

const CompanyId = ({ values, setFieldValue, errors, submitCount }) => {
  return (
    <Section id="company-id" title="Company ID">
      <div className="columns">
        <div className="column">
          <UploadDocumentDropzone
            docType="id_front"
            label="Upload Company ID (Front)"
            icon="id"
            maxFiles={1}
            dispatchType="SAVE_ID_FRONT"
            name="id.front"
            value={values?.id?.front}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
          />
        </div>
        <div className="column">
          <UploadDocumentDropzone
            docType="id_back"
            label="Upload Company ID (Back)"
            icon="id"
            maxFiles={1}
            dispatchType="SAVE_ID_BACK"
            name="id.back"
            value={values?.id?.back}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
          />
        </div>
      </div>
    </Section>
  )
}

export default CompanyId
