import { getSignedInUser } from "../../Auth/services/user"
import { GATSBY_ALLOWED_DOMAINS } from "gatsby-env-variables"

export const isPersonalEmail = () => {
  const user = getSignedInUser()?.authUser
  let email = user?.email || ""
  let emailDomain = email?.split("@")[1] || ""

  return !GATSBY_ALLOWED_DOMAINS?.includes(emailDomain)
}
